import React, { useState, useRef, useEffect } from "react";
// import Helmet from 'react-helmet';
// import { BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

import { Link } from "gatsby";

import { List, ListItem, ListItemText } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";


import Grid from "@material-ui/core/Grid";
import NiinaHeader from "../components/NiinaHeader";
import NiinaFooter from "../components/NiinaFooter";
import { Helmet } from "react-helmet-async";

function Tips() {
  // const classes = useStyles();

  return (
    <div style={{ minWidth: "320px" }}>
      <Helmet>
        <html lang="ja" />
        <title>
          焼き立ての「いいな」を届けるパン屋 『Bakery
          Niina(ベーカリー・ニーナ)』 | こだわり
        </title>
        <meta
          name="description"
          content="山形のみなさんに、パン職人が一晩かけて仕込むこだわりパンを一番おいしい状態で届けたい。焼き立てパンが香る店『Bakery Niina（ベーカリーニーナ）』です。多い日には１日５０回焼き上げるため、いつでも焼きたてのパンが楽しめます。"
        />
      </Helmet>

      <NiinaHeader />
      <TipsComponent />
      <NiinaFooter />
    </div>
  );
}

export default Tips;

function TipsComponent() {
  return (
    <div className="App">
      <div
        style={{
          display: "row",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{
            marginTop: "1em",
            minWidth: "160px",
            maxWidth: "30%",
          }}
          src="../images//img/photos/ヘッダーロゴ@3x.png"
        />
      </div>

      <div
        style={{
          marginTop: "3.2em",
          display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          placeholder="none"
          imgStyle={{
            height: "100%",
            width: "auto",
            margin: "auto",
          }}
          style={{
            height: "12vw",
            width: "100vw",
          }}
          src="../images//img/photos/こだわり見出し@3x.png"
        />
      </div>
      <div
        style={{
          //            display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{
            minWidth: "160px",
            maxWidth: "85%",
            marginBottom: "1.8em",
          }}
          src="../images//img/3x/アセット 9@3x.png"
        />
      </div>

      <div
        style={{
          display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{ minWidth: "160px", maxWidth: "90%" }}
          src="../images//img/photos/オーバーナイト@3x.jpg"
        />
      </div>

      <div
        style={{
          marginLeft: "2em",
          textAlign: "left",
          //            display: "row",
          fontSize: mainFontSize,
        }}
      >
        <p>
          小麦の旨味がさらに深みを増したパンを、
          <br />
          いつでも焼き立てで提供できるのが、
          <br />
          Niinaのオーバーナイト製法です。
        </p>
        <p>
          すぐに焼ける状態で一晩ほどじっくり時間をかけて
          <br />
          発酵させることが美味しさと、
          <br />
          いつでも焼き立てを提供できる秘密です。
        </p>
      </div>

      <div
        style={{
          marginTop: "3.2em",
          //            display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{ minWidth: "160px", maxWidth: "70%" }}
          src="../images//img/3x/アセット 10@3x.png"
        />
      </div>

      <div
        style={{
          marginLeft: "2em",
          textAlign: "left",
          // display: "row",
          fontSize: mainFontSize,
        }}
      >
        {" "}
        <p>
          朝から美味しいパンの幸せを感じてもらうために、
          <br />
          Bakery Niinaは通勤時間に合わせて
          <br />
          早朝から営業いたします。
        </p>
        <p>
          食べればサクサク、バターじんわりの当店自慢の
          <br />
          クロワッサンを是非朝食に
          <br />
          一度食べてみてください。
        </p>
      </div>

      <div
        style={{
          // display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{ minWidth: "160px", maxWidth: "90%" }}
          src="../images//img/photos/焼き立て.jpg"
        />
      </div>

      <div
        style={{
          marginTop: "3.2em",
          display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{ minWidth: "160px", maxWidth: "70%" }}
          src="../images//img/3x/アセット 11@3x.png"
        />
      </div>

      <div
        style={{
          marginLeft: "2em",
          textAlign: "left",
          display: "row",
          fontSize: mainFontSize,
        }}
      >
        {" "}
        <p>
          濃厚な味わいの、本格的で美味しい
          <br />
          フランスおやつパンをご準備いたします。
          <br />
        </p>
        <p>
          お客様とのコミュニケーションの中から
          <br />
          アイディアをいただき、
          <br />
          新しいパンへの挑戦をし続けます。
        </p>
      </div>

      <div
        style={{
          //            display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{
            minWidth: "160px",
            maxWidth: "90%",
            marginBottom: "3em",
          }}
          src="../images//img/photos/品揃え.jpg"
        />
      </div>
    </div>
  );
}

const mainFontSize = "3.8vw";
